import { logEvent } from "firebase/analytics";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react"
import { analytics, firestore, default as app  } from "../lib/firebase";
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

const emailRegexp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export default function ContactForm() {
    useEffect(() => {
        if(window) {
            if(process.env.NODE_ENV === 'development') {
                // @ts-ignore
                self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
            }
            initializeAppCheck(app, {
                provider: new ReCaptchaV3Provider('6Lf4TT8jAAAAAFryGdOKAvkpIu9QyMfzW4CCOvFr'),
                isTokenAutoRefreshEnabled: true,
            });
        }
    }, [app])
        
     // Form logic
     const [canSend, setCanSend] = useState(false);
     const [sending, setSending] = useState(false);
     const [sentOk, setSentOk] = useState(false);
     const [name, setName] = useState("");
     const [email, setEmail] = useState("");
     const [message, setMessage] = useState("");
 
     const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
         setName(e.target.value)
     }, [setName]);
 
     const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
         setEmail(e.target.value);
     }, [setEmail]);
 
     const onMessageChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
         setMessage(e.target.value);
     }, [setMessage]);
 
     // Form send update
     useEffect(() => {
         setCanSend(name.length > 0 && emailRegexp.test(email) && message.length > 0);
     }, [name, email, message]);
 
     const onSend = useCallback(async () => {
         setCanSend(false);
         setSending(true);
 
         const payload = {
             to: ['info@voces.site'],
             replyTo: email,
             message: {
               subject: `voces.site: Nuevo mensaje de ${name}`,
               text: message
             },
             name,
             email,
             time: serverTimestamp()
         }
         const messages = collection(firestore(), 'messages');
         try {
             await addDoc(messages, payload);
             logEvent(analytics(), 'contact_form_sent');
             setSentOk(true);
         } catch(e) {
             console.error('Error sending message', e);
             setCanSend(true);
         } finally {
             setSending(false);
         }
     }, [analytics, name, email, message]);

     
     return (
        <form className="font-nunito flex flex-col">
            {/* <label htmlFor="name">Nombre*</label> */}
            <input className="my-2" type="text" placeholder="Nombre" id="name" value={name} onChange={onNameChange} />
            {/* <label htmlFor="email">Correo electrónico*</label> */}
            <input className="my-2" type="email" placeholder="Correo electrónico" id="email" value={email} onChange={onEmailChange} />
            {/* <label htmlFor="message">Escribe tu mensaje aqui:</label> */}
            <textarea className="my-2" id="message" placeholder="Escribe tu mensaje aquí" rows={5} value={message} onChange={onMessageChange} />
            <div className="py-2 flex items-center">
            <input onClick={onSend} type="button" disabled={!canSend} value={sending ? "Enviando..." : "Enviar"} className={"transition-colors px-6 py-4 font-nunito border-2 rounded-full border-white " + (canSend ? "cursor-pointer text-black bg-white hover:bg-slate-100" : "cursor-not-allowed text-slate-400 bg-slate-200")} />
            {sentOk && <div className="px-5 text-white text-lg">¡Tu mensaje se ha enviado correctamente!</div>}
            </div>
        </form>
     )
}
