import React, { useEffect } from "react"
import { faInstagram, faTiktok, faYoutube, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"
import ActionButton from "../components/action-button"
import ContactForm from "../components/contact-form"
import { logEvent } from "firebase/analytics"
import { analytics } from "../lib/firebase"
// @ts-ignore
import LandingMp4 from "../images/landing.mp4"
// @ts-ignore
import LandingWebM from "../images/landing.webm"
// @ts-ignore
import LandingPlaceholder from "../images/landing-placeholder.png"

export default function HomePage() {
    useEffect(() => {
        if(window) {
          logEvent(analytics(), 'visited_homepage');
        }
      }, [analytics]);

    return (
        <div>
            <SEO title="Ivanna Ochoa" />
            <header className="fixed md:sticky w-full top-0 z-50 bg-black text-white p-5 md:flex">
                <div className="lg:text-left text-center"><h2>Ivanna Ochoa</h2></div>
                <div className="md:grow"></div>
                <div className="lg:text-left text-center md:pt-0 pt-5">
                    <a className="px-2" href="#top">Inicio</a>
                    <a className="px-2" target="_blank" href="https://www.instagram.com/ivanna8a/"><FontAwesomeIcon icon={faInstagram} /></a>
                    <a className="px-2" target="_blank" href="https://www.facebook.com/Ivanna8a"><FontAwesomeIcon icon={faFacebookF} /></a>
                    <a className="px-2" target="_blank" href="https://www.youtube.com/c/ivannaochoaV"><FontAwesomeIcon icon={faYoutube} /></a>
                    <a className="px-2" target="_blank" href="https://www.tiktok.com/@ivanna8av"><FontAwesomeIcon icon={faTiktok} /></a>
                </div>
            </header>
            <main className="relative" id="top">
                <section className="bg-black relative min-h-screen md:min-h-fit w-full">
                    <video playsInline autoPlay muted loop poster={LandingPlaceholder} className="object-cover min-h-screen md:min-h-0 min-w-full md:min-h-full max-h-screen z-0">
                        <source src={LandingMp4} type="video/mp4" />
                        <source src={LandingWebM} type="video/webm" />
                    </video>
                    <div className="absolute left-0 top-0 z-10 min-w-full min-h-full bg-landing absolute items-center flex justify-center">
                        <h1 className="text-center text-white">Bienvenidos a mi mundo de la voz</h1>
                    </div>
                </section>
                <section className="bg-white">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5 px-5 lg:px-0 max-w-4xl mx-auto items-center">
                        <div className="py-5 md:py-10">
                            <StaticImage src="../images/ivanna-personajes.png" alt="Ivanna y sus personajes" />
                        </div>
                        <div className="py-5 md:py-10">
                            <h2 className="text-center md:text-left">¡Mucho gusto!</h2>
                            <br />
                            <p className="text-justify">
                                ¡Hola! Me llamo Ivanna :)
                                <br/><br/>
                                Desde pequeña me dijeron que me tenía que enfocar en una sola cosa, que se debe buscar una
                                única pasión, desarrollar un solo talento… Pues yo creo que todos tenemos dentro múltiples
                                talentos y que debemos ir a conocerlos si nos da curiosidad alguno. Luego de convencer a mis
                                padres a los 15 años para que me llevaran a una escuela de doblaje decidí ir a descubrir si
                                podía desarrollar este talento. Y así fue. Hoy en día puedo presentarles muchos personajes
                                que amo y admiro a los que les he puesto mi voz con mucho cariño e ilusión. Está bien,
                                no fue tan fácil, en el camino no dejé mis estudios y actualmente soy reclutadora en una 
                                Fintech y profesora de doblaje en la Universidad del Zulia. Sigo trabajando a distancia para
                                estudios de doblaje y audiolibros y me emociona enseñarte todo lo que he aprendido en
                                estos 12 años de carrera profesional en el medio.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="bg-gradient-to-b from-cyan-300 to-purple-400 text-white">
                    <div className="max-w-4xl mx-auto py-10 grid grid-cols-1 md:grid-cols-3 md:gap-10 px-5 lg:px-0">
                        <div className="text-center w-full pb-10 md:pb-0">
                            <h2>Clientes</h2>
                            <br />
                            <p>Estas son las marcas que confían en mi voz:</p>
                        </div>
                        <div className="col-span-2 px-5 md:px-0">
                            <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                                <div className="flex items-center">
                                    <StaticImage src="../images/nickelodeon.png" alt="Nickelodeon" />
                                </div>
                                <div className="flex items-center">
                                    <StaticImage src="../images/cn.png" alt="Cartoon Network" />
                                </div>
                                <div className="flex items-center">
                                <StaticImage className="mx-auto" src="../images/hbo.webp" alt="HBO" />
                                </div>
                                <div className="flex items-center">
                                    <StaticImage src="../images/disney_junior.webp" alt="Disney Junior" />
                                </div>
                                <div className="flex items-center">
                                    <StaticImage src="../images/spotify.png" alt="Spotify" />
                                </div>
                                <div className="flex items-center">
                                <StaticImage src="../images/lifetime.webp" alt="Lifetime" />
                                </div>
                                <div className="flex items-center">
                                <StaticImage className="w-20 mx-auto" src="../images/history.png" alt="History Channel" />
                                </div>
                                <div className="flex items-center">
                                    <StaticImage src="../images/ae.png" alt="A&E" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-white">
                    <div className="max-w-4xl mx-auto py-10 text-center">
                        <h2>Cursos de Voz</h2>
                        <div className="py-10 grid grid-cols-1 md:grid-cols-3 gap-16 md:gap-2 lg:gap-5 px-5 md:px-4 lg:px-0">
                            <div>
                                <h3>Doblaje de voces</h3>
                                <StaticImage className="my-4" src="../images/doblaje.png" alt="Doblaje de voces" />
                                <p className="mb-7">
                                    Conoce el mundo del doblaje de voces con este curso online que te 
                                    dará las herramientas y conocimientos que necesitas para explotar tu voz.
                                </p>
                                <ActionButton href="https://voces-site.thinkific.com/courses/doblaje-de-voces">¡Me anoto!</ActionButton>
                            </div>
                            <div>
                                <h3>Coaching Personalizado</h3>
                                <StaticImage className="my-4" src="../images/coaching.png" alt="Coaching Personalizado" />
                                <p className="mb-7">
                                    Grabarás a distancia conmigo: Un doblaje de caricatura, uno de película, 
                                    un reality show, y un documental. Prepara tus audífonos para una sesión conmigo.
                                </p>
                                <ActionButton href="mailto:info@voces.site?subject=Solicitud Coaching Personalizado">¡Me anoto!</ActionButton>
                            </div>
                            <div>
                                <h3>Actuación para el doblaje</h3>
                                <StaticImage className="my-4" src="../images/acting.png" alt="Actuación" />
                                <p className="mb-7">
                                La actuación es fundamental para un actor de doblaje, 
                                profundiza técnicas y conceptos que mejorarán la actuación a través de la voz con este curso online.
                                </p>
                                <ActionButton href="mailto:info@voces.site?subject=Solicitud Curso Actuación">¡Me anoto!</ActionButton>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-bg text-white">
                    <div className="max-w-4xl mx-auto py-10">
                        <h2 className="text-center ">Servicios</h2>
                        <p className="text-center">Mi equipo y yo también te podemos ayudar con:</p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 py-10 px-5 lg:px-0">
                            <div>
                                <StaticImage className="rounded-full" imgStyle={{ borderRadius: '9999px' }} src="../images/mic.jpg" alt="Voice Over" />
                                <h2 className="font-domine text-center py-5">Voice-Over</h2>
                                <ul className="px-10 font-nunito list-disc">
                                    <li>Spots para radio, TV o Redes Sociales</li>
                                    <li>Doblaje de voces</li>
                                    <li>Narración de audiolibros</li>
                                    <li>Voiceover para Youtube</li>
                                </ul>
                            </div>
                            <div>
                                <StaticImage className="rounded-full" imgStyle={{ borderRadius: '9999px' }} src="../images/camera.jpg" alt="Traducciones" />
                                <h2 className="font-domine text-center py-5">Traducción</h2>
                                <ul className="px-10 font-nunito list-disc">
                                    <li>Adaptación de textos</li>
                                    <li>Subtitulado</li>
                                    <li>Traducción</li>
                                    <li>Editor de diálogos</li>
                                </ul>
                            </div>
                            <div>
                                <StaticImage className="rounded-full" imgStyle={{ borderRadius: '9999px' }} src="../images/headphones.jpg" alt="Mezcla" />
                                <h2 className="font-domine text-center py-5">Mezcla y Sonido</h2>
                                <ul className="px-10 font-nunito list-disc">
                                    <li>Mezcla de sonido</li>
                                    <li>Reparación de audio</li>
                                    <li>Foley</li>
                                    <li>Música</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-contact text-white">
                   <div className="max-w-4xl grid grid-cols-1 md:grid-cols-3 md:gap-10 mx-auto py-10 lg:px-0 px-5">
                        <div className="md:text-left text-center w-full pb-10 md:pb-0">
                            <StaticImage className="rounded-full" imgStyle={{ borderRadius: '9999px' }} src="../images/contact.png" alt="Contacto" />
                        </div>
                        <div className="col-span-2">
                            <h2>Contacto</h2>
                            <ContactForm />
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}
