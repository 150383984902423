import React, {PropsWithChildren, ReactNode} from "react";
import {Link} from "gatsby";

export interface ActionButtonProps {
    to?: string,
    href?: string,
}

export default function ActionButton({children, to, href}: PropsWithChildren<ActionButtonProps>) {

    return (
        <>
            {to 
                ? <Link to={to}>
                <span
                    className={"cursor-pointer transition-colors px-6 py-4 font-nunito border-2 rounded-full border-black text-black hover:bg-black hover:text-white"}>
                    {children}
                </span>
            </Link>
            : (<a href={href}>
                <span
                    className={"cursor-pointer transition-colors px-6 py-4 font-nunito border-2 rounded-full border-black text-black hover:bg-black hover:text-white"}>
                    {children}
                </span>
            </a>)}
        </>
    )
}