import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyD2fcZrXw0MR9Fw0qnA1N1ZL7pZNkLJdPk",
    authDomain: "voces-site.firebaseapp.com",
    databaseURL: "https://voces-site.firebaseio.com",
    projectId: "voces-site",
    storageBucket: "voces-site.appspot.com",
    messagingSenderId: "1073950897022",
    appId: "1:1073950897022:web:ba9dad1a2283c10ef9f92f",
    measurementId: "G-3BTDE9H697"
}

const app = initializeApp(firebaseConfig);

export default app;

export function analytics() { return getAnalytics(app) };
export function firestore() { return getFirestore(app) };